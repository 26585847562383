import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import Header from "./header";
import { Footer } from "react-materialize";

import "materialize-css/dist/js/materialize.js";
import "../styles/main.scss";

const Layout = ({ children, isHome, isWhite, currentPage }) => {
    useEffect(() => {
        document.addEventListener("scroll", debounce(storeScroll), {
            passive: true,
        });
        storeScroll();
    }, []);

    const debounce = (fn) => {
        let frame;
        return (...params) => {
            if (frame) {
                cancelAnimationFrame(frame);
            }
            frame = requestAnimationFrame(() => {
                fn(...params);
            });
        };
    };

    const storeScroll = () => {
        document.documentElement.dataset.scroll = window.scrollY;
    };

    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);

    return (
        <div className={((isHome || isWhite) ? 'home-page ' : ' ')  + currentPage}>
            <Header
                siteTitle={data.site.siteMetadata.title}
                isHome={isHome}
                isWhite={isWhite}
                currentPage={currentPage}
            />
            <div>{children}</div>
            <Footer
                className="blue-grey darken-4 scrollspy"
                id="contact"
                copyrights="© 2015-2020 Atamagai doo. Sva prava zadržana"
                links={
                    <div className="right">
                        <p
                            className="grey-text text-lighten-1"
                            style={{ marginBottom: "0px" }}
                        >
                            Subotička 19a, Beograd
                        </p>
                        <a
                            className="grey-text text-lighten-1"
                            href="mailto:office@atamagai.com"
                        >
                            office@atamagai.com
                        </a>
                        <p
                            className="grey-text text-lighten-1"
                            style={{ margin: "0px" }}
                        >
                            060 399 00 20
                        </p>
                    </div>
                }
                moreLinks={
                    <a
                        className="grey-text text-lighten-1 right"
                        href="http://www.thesolidus.com"
                    >
                        thesolidus.com
                    </a>
                }
            >
                <h5 className="white-text">Atamagai doo</h5>
                <h6
                    className="red-text text-lighten-2"
                    style={{ margin: "0px", fontWeight: "300" }}
                >
                    professionals with passion
                </h6>
                <p className="grey-text text-lighten-1">
                    Inovativna poslovna softverska rešenja za digitalno doba
                    povezanih uređaja i interneta
                </p>
            </Footer>
        </div>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    isHome: PropTypes.bool.isRequired,
    isWhite: PropTypes.bool.isRequired,
    currentPage: PropTypes.string.isRequired,
};

Layout.defaultProps = {
    isHome: false,
    isWhite: false,
    currentPage: "home",
};

export default Layout;
