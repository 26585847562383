import PropTypes from "prop-types";
import React from "react";
import { Navbar, NavItem } from "react-materialize";
import AtamagaiLogo from "../assets/logo.svg";

const Header = ({ siteTitle, isHome, isWhite, currentPage }) => {
    return (
        <Navbar
            className={'blue-grey darken-4 z-depth-0 ' + ((isHome || isWhite) ? 'home-page ' : ' ')  + currentPage }
            alignLinks="right"
            brand={
                <a className="header-logo" href="/">
                    <AtamagaiLogo style={{ maxHeight: "50px" }} />
                </a>
            }
            id="mainMenu"
            options={{
                draggable: true,
                edge: "left",
                inDuration: 250,
                onCloseEnd: null,
                onCloseStart: null,
                onOpenEnd: null,
                onOpenStart: null,
                outDuration: 200,
                preventScrolling: true,
            }}
            fixed
        >
            { currentPage === "blog" && <NavItem className={currentPage === "blog" ? "active" : ''} href="/blog/">&nbsp;&nbsp;&nbsp;NAZAD&nbsp;&nbsp;&nbsp;</NavItem>}
            {!isHome && currentPage !== "blog" && <NavItem href="/">Početna</NavItem>}
            {isHome && currentPage !== "blog" && <NavItem href="#about">O nama</NavItem>}
            {isHome && currentPage !== "blog" && <NavItem href="#products">Proizvodi</NavItem>}
            {isHome && currentPage !== "blog" && <NavItem href="#services">Usluge</NavItem>}
            {isHome && currentPage !== "blog" && <NavItem href="#contact">Kontakt</NavItem>}            
            {currentPage !== "blog" && <NavItem className={currentPage === "ads" ? "active" : ''} href="/ads/">ADS sistem</NavItem>}
            {/* {currentPage !== "blog" && <NavItem className={currentPage === "bloglist" ? "active" : ''} href="/blog/">Blog</NavItem>} */}
        </Navbar>
    );
};

Header.propTypes = {
    siteTitle: PropTypes.string,
    isHome: PropTypes.bool,
};

Header.defaultProps = {
    siteTitle: `Atamagai`,
    isHome: false,
};

export default Header;
